import { Field, Form, Formik, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";

import { Alert, Button, Card, Heading, addToast } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider.js";
import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { PROPERTY_ALARM_UPDATE_TYPE } from "consts";
import {
  useSendPropertyProfileUpdateNotificationMutation,
  useUpdatePolicyIntervalDataMutation,
} from "gql/__generated__/hooks.js";
import { en as locale } from "locale";
import { formatDate } from "utils";
import { logError, scrollToTop, trackEvent } from "utils";

import styles from "./update-security.module.scss";

type SecurityFormValues = {
  burglarAlarm: string;
  fireAlarm: string;
};

const ReviewSecurity = ({ setReview, error }) => {
  const {
    isSubmitting,
    values: { burglarAlarm, fireAlarm },
  } = useFormikContext<SecurityFormValues>();

  return (
    <Card className={styles.reviewCard}>
      <Form>
        <Stack gap="md">
          <Heading size="lg">{locale.propertyProfile.pleaseReview}</Heading>

          {error && <Alert appearance="danger" title="" description={error} />}
          <Heading size="sm">{locale.propertyPage.security}</Heading>

          <Body>{locale.updateSecurity.securityDescription}</Body>

          <InlineDataRow className={styles.dataRow}>
            <DataTerm>{locale.updateSecurity.burglarAlarm}</DataTerm>
            <DataDefinition>{burglarAlarm}</DataDefinition>
          </InlineDataRow>

          <InlineDataRow className={styles.dataRow}>
            <DataTerm>{locale.updateSecurity.fireAlarm}</DataTerm>
            <DataDefinition>{fireAlarm}</DataDefinition>
          </InlineDataRow>

          <div className={styles.navigation}>
            <Button appearance="tertiary" onClick={() => setReview(false)}>
              {locale.easypayEnroll.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              type="submit"
              disabled={isSubmitting}
              data-testid="security-submit-btn"
            >
              {isSubmitting
                ? locale.propertyProfile.submitting
                : locale.propertyProfile.submitUpdates}
            </Button>
          </div>
        </Stack>
      </Form>
    </Card>
  );
};

const UpdateSecurity = ({
  onClose,
  onUpdateSuccess,
  burglarAlarm,
  fireAlarm,
  effectiveDate,
}) => {
  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();
  const [updatePolicyIntervalData] = useUpdatePolicyIntervalDataMutation();

  const initialValues = {
    burglarAlarm: burglarAlarm,
    fireAlarm: fireAlarm,
  };
  const handleSubmit = async (
    { burglarAlarm, fireAlarm },
    { setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      trackEvent("Property security - Updates submitted", {
        burglarAlarm,
        fireAlarm,
      });

      const endorsementUpdates = {
        fireAlarm: PROPERTY_ALARM_UPDATE_TYPE[fireAlarm],
        burglarAlarm: PROPERTY_ALARM_UPDATE_TYPE[burglarAlarm],
      };

      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates,
        },
        context: {
          clientName: "keystone-api",
        },
      });

      if (fireAlarm !== "Central alarm" || burglarAlarm !== "Central alarm") {
        const autoEndorsementUpdates = {
          fireAlarm:
            fireAlarm === "Central alarm"
              ? undefined
              : PROPERTY_ALARM_UPDATE_TYPE[fireAlarm],
          burglarAlarm:
            burglarAlarm === "Central alarm"
              ? undefined
              : PROPERTY_ALARM_UPDATE_TYPE[burglarAlarm],
        };

        await updatePolicyIntervalData({
          variables: {
            policyID: selectedPolicyId,
            emailAddress: userInfo?.email,
            username: userInfo?.email,
            endorsementUpdates: autoEndorsementUpdates,
          },
          context: {
            clientName: "keystone-api",
          },
        });
      }

      if (data) {
        trackEvent("Property security - Update completed");
        onUpdateSuccess();
        const hasFutureEffectiveDate = new Date(effectiveDate) > new Date();
        const toastMessage = hasFutureEffectiveDate
          ? `We have recorded your changes. They will be reflected on the platform after your policy gets renewed on ${formatDate(
              effectiveDate,
              "MM/DD/yyyy"
            )}.`
          : "We have received your update! Once validated, you will see these changes reflected here on your property profile.";
        addToast(toastMessage, { icon: true, duration: 10000 });
        onClose();
      }
    } catch (error) {
      logError(`Update security: ${error.message}`);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property security - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <UpdateSecurityForm onClose={onClose} />
    </Formik>
  );
};

const UpdateSecurityForm = ({ onClose }) => {
  const {
    setFieldValue,
    status,
    values: { burglarAlarm, fireAlarm },
  } = useFormikContext<SecurityFormValues>();
  const [review, setReview] = useState(false);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    if (status?.type === "error") {
      setError(locale.propertyProfile.error);
    }
  }, [status]);

  useEffect(() => {
    setError(null);
    scrollToTop();
  }, [review]);

  return (
    <>
      <ProgressBar progress={review ? 90 : 50} />
      <MainLayout
        sidebar={
          review ? (
            <PropertyProfileSidebar
              headline={locale.updateSecurity.nextSteps}
              body={locale.updateSecurity.nextStepsDescription}
            />
          ) : (
            <PropertyProfileSidebar
              headline={locale.updateRoof.documentation}
              body={locale.updateSecurity.documentationDescription}
            />
          )
        }
      >
        {review ? (
          <ReviewSecurity setReview={setReview} error={error} />
        ) : (
          <Card className={styles.updateSecurity}>
            <Heading size="lg">{locale.updateSecurity.pleaseUpdate}</Heading>
            <br />
            <Form>
              <Body>{locale.updateSecurity.doYouHaveBurglarAlarm}</Body>
              <Field
                name="burglarAlarm"
                component={RadioButtonGroup}
                className={styles.radioGroup}
                fieldChildren={() => (
                  <>
                    <Field
                      name="burglarAlarm"
                      id="burglarNone"
                      label={locale.updateSecurity.no}
                      className={styles.radio}
                      onChange={() => setFieldValue("burglarAlarm", "None")}
                      component={RadioButtonField}
                      checked={burglarAlarm === "None"}
                    />
                    <Field
                      name="burglarAlarm"
                      id="burglarLocal"
                      label={locale.updateSecurity.yesLocal}
                      className={styles.radio}
                      onChange={() =>
                        setFieldValue("burglarAlarm", "Local alarm")
                      }
                      component={RadioButtonField}
                      checked={burglarAlarm === "Local alarm"}
                    />
                    <Field
                      name="burglarAlarm"
                      id="burglarCentral"
                      label={locale.updateSecurity.yesCentral}
                      className={styles.radio}
                      onChange={() =>
                        setFieldValue("burglarAlarm", "Central alarm")
                      }
                      component={RadioButtonField}
                      checked={burglarAlarm === "Central alarm"}
                    />
                  </>
                )}
              />

              <Body>{locale.updateSecurity.doYouHaveFireAlarm}</Body>
              <Field
                name="fireAlarm"
                component={RadioButtonGroup}
                className={styles.radioGroup}
                fieldChildren={() => (
                  <>
                    <Field
                      name="fireAlarm"
                      id="fireNone"
                      label={locale.updateSecurity.no}
                      className={styles.radio}
                      onChange={() => setFieldValue("fireAlarm", "None")}
                      component={RadioButtonField}
                      checked={fireAlarm === "None"}
                    />
                    <Field
                      name="fireAlarm"
                      id="fireLocal"
                      label={locale.updateSecurity.yesLocal}
                      className={styles.radio}
                      onChange={() => setFieldValue("fireAlarm", "Local alarm")}
                      component={RadioButtonField}
                      checked={fireAlarm === "Local alarm"}
                    />
                    <Field
                      name="fireAlarm"
                      id="fireCentral"
                      label={locale.updateSecurity.yesCentral}
                      className={styles.radio}
                      onChange={() =>
                        setFieldValue("fireAlarm", "Central alarm")
                      }
                      component={RadioButtonField}
                      checked={fireAlarm === "Central alarm"}
                    />
                  </>
                )}
              />
            </Form>
            <div className={styles.navigation}>
              <Button appearance="tertiary" onClick={onClose}>
                {locale.easypayEnroll.goBack}
              </Button>
              <Button
                appearance="primary"
                size="default"
                onClick={() => setReview(true)}
                disabled={!fireAlarm || !burglarAlarm}
                data-testid="security-continue-btn"
              >
                {locale.easypayEnroll.continue}
              </Button>
            </div>
          </Card>
        )}
      </MainLayout>
    </>
  );
};

export default UpdateSecurity;
