import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import { Heading, Spinner } from "@icg360/design-system";

import { LogoImageLink } from "components/shared/logo-link";
import { en as locale } from "locale";

import styles from "./loading-page.module.scss";

const LoadingPage = ({ title }) => (
  <>
    <Container>
      <header className={styles.header}>
        <LogoImageLink />
      </header>
    </Container>
    <div className={styles.loadingContent}>
      <Heading size="xl">{title}</Heading>
      <p className={styles.loadingText}>{locale.paragraphs.loadingPage}</p>
      <Spinner />
    </div>
  </>
);

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadingPage;
