import type { MssConfig } from "./types";

export const CONFIG: MssConfig = {
  APP_ENV: "staging",
  AUTH0_DOMAIN: "stage-ss.auth0.com",
  AUTH0_CLIENT_ID: "gimV99LhcA1ypauxHD8b5KFvnxlDpQBq",
  COOKIE_DOMAIN: "my.stage.sagesure.com",
  KEYSTONE_API_HREF: "https://stage-sagesure-svc.icg360.org/cru-4/keystone",
  PAYMENT_API_HREF:
    "https://stage-sagesure-svc.icg360.org/cru-4/payments/graphql",
  MIXPANEL_TOKEN: "f6b387f2872ec67842e9d7078f236103",
  LAUNCH_DARKLY_CLIENT_ID: "5ac7bd3ecda8352c5ffc54c3",
  KEYSTONE_PROXY_HREF: "https://my.stage.sagesure.com/p",
  GOOGLE_MAPS_KEY: "AIzaSyDf1qLOtIdLc7mELYNd_Ri1HtdahKxcwlk",
  CHAT_UUID: "script_4799626315f3ad7f4a5c865.52296286",
  CHAT_CHANNEL_UUID: "TODO:GetThisFrom8X8",
  CHAT_CHANNEL: "MySageSureChatStaging(Never Use)",
  IPC_MANAGER_URL:
    "https://stage-sagesure-ipc-manager.icg360.org/quotes-and-policies",
  FNOL_DEFINITIONS_URL:
    "https://sagesure-claims-fnol-forms-ss-stage.s3.amazonaws.com/public",
  GOOGLE_OAUTH_CLIENT_ID:
    "71648894609-77h10a2n5npjf62l5nu6vbmthnuc0n38.apps.googleusercontent.com",
  THUMBTACK_API_HREF: "https://api.thumbtack.com/v1",
  THUMBTACK_AUTH:
    "U2FnZXN1cmU6WDBNMVg4aEZ1RjFlS3FHLTNfd2JaU3pMQWRmcGlGUWEwZWdncGdBNEpuWT0=",
  OTEL_COLLECTOR: "/p/telemetry/traces",
  QUALTRICS_PROJECT_ID: "ZN_6g5mNAJPqHG6B1A",
};
