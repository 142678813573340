import { useContext, useEffect, useState } from "react";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  Card,
  Link as DSLink,
  Heading,
  Icon,
} from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Body } from "components/common/body";
import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { CustomerCareContactModal } from "components/customer-care-contact-modal";
import PropertyMessages from "components/home-services/property-messages";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { PROPERTY_ROOF_UPDATE_TYPE } from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { formatDate, trackEvent, useFlags } from "utils";

import { usePropertyProfilePage } from "./hooks";
import { PoolData } from "./pool-data";
import styles from "./property-profile.module.scss";
import UpdatePool from "./update-pool";
import UpdateSecurity from "./update-security";
import UpdateTrampoline from "./update-trampoline";

const PropertyProfile = () => {
  const {
    propertyProfileDwelling,
    propertyProfilePool,
    propertyProfileSecurityDetails,
    propertyProfileTrampoline,
    propertyPagePoolValues,
    showPropertyTabDetailsSection,
    universalLogin,
    retireProxy,
  } = useFlags();
  const navigate = useNavigate();
  const { selectedPolicyId, userDetails } = useContext(AuthAppContext);
  const loaderData = useLoaderData() as PropertyProfileLoaderData;

  const [hasFutureEffectiveDate, setHasFutureEffectiveDate] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const {
    data: propertyProfileData,
    loading,
    error,
  } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const { userPropertyProfileData } = propertyProfileData ?? {};

  const {
    propertyData,
    poolData,
    updateForm,
    setUpdateForm,
    propertyUpdated,
    setPropertyUpdated,
  } = usePropertyProfilePage(
    universalLogin && retireProxy
      ? loaderData?.propertyProfile
      : userPropertyProfileData
  );

  useEffect(() => {
    trackEvent("Property Care - Property Care Module Displayed", {
      homeServices: "propertyCare",
    });
  }, []);

  useEffect(() => {
    if (userDetails) {
      setHasFutureEffectiveDate(
        new Date(userDetails?.currentTerm?.effectiveDatePolicyTerm) > new Date()
      );
    }
  }, [userDetails]);

  if (userDetails?.isCommercial) {
    return <Navigate to={"/my/overview"} replace />;
  }

  if (error) {
    return (
      <BaseLayout>
        <MainLayout
          sidebar={
            showPropertyTabDetailsSection ? (
              <FaqSidebar page="property" />
            ) : null
          }
        >
          <FetchingError page="Property" />
        </MainLayout>
      </BaseLayout>
    );
  }

  if (loading) {
    return <BaseLayout loading />;
  }

  const {
    burglarAlarm,
    constructionYearRoof,
    fireAlarm,
    roofCoveringType,
    trampoline,
  } = propertyData ?? {};
  const { poolType, poolFence } = poolData ?? {};

  const sidebar = (
    <div className={styles.profileSidebar}>
      <Stack gap="sm">
        <InsuranceRepresentative />
        {showPropertyTabDetailsSection ? <FaqSidebar page="property" /> : null}
      </Stack>
    </div>
  );

  if (updateForm === "security") {
    return (
      <BaseLayout hideNavigation>
        <UpdateSecurity
          onClose={() => setUpdateForm("")}
          onUpdateSuccess={() => setPropertyUpdated(true)}
          burglarAlarm={burglarAlarm.value}
          fireAlarm={fireAlarm.value}
          effectiveDate={userDetails?.currentTerm?.effectiveDatePolicyTerm}
        />
      </BaseLayout>
    );
  } else if (updateForm === "pool") {
    return (
      <BaseLayout hideNavigation>
        <UpdatePool
          onClose={() => setUpdateForm("")}
          onUpdateSuccess={() => setPropertyUpdated(true)}
        />
      </BaseLayout>
    );
  } else if (updateForm === "trampoline") {
    return (
      <BaseLayout hideNavigation>
        <UpdateTrampoline
          onClose={() => setUpdateForm("")}
          onUpdateSuccess={() => setPropertyUpdated(true)}
        />
      </BaseLayout>
    );
  }

  const mainContent = (
    <div className={styles.profileBody}>
      <PropertyMessages />
      {showPropertyTabDetailsSection && (
        <Card title="Property details">
          <section className={styles.propertySection}>
            <Body>
              We have the following details on file. Make updates below if
              anything has changed. You may be eligible for a lower insurance
              premium. For any other changes, contact your insurance
              representative or{" "}
              <DSLink onClick={() => setShowContactModal(true)}>
                SageSure customer care
              </DSLink>
              .
            </Body>
            {!propertyUpdated && (
              <div className={styles.dontSeeNotification}>
                {hasFutureEffectiveDate ? (
                  <Alert
                    appearance="warning"
                    title={`Any changes made will be reflected after policy renewal date on ${formatDate(
                      userDetails?.currentTerm?.effectiveDatePolicyTerm,
                      "MM/DD/yyyy"
                    )}`}
                    description="Your new policy term will begin soon. Any changes made to your property will be reflected after the start of your next policy term."
                  />
                ) : (
                  <Alert
                    title="Don't see your updated changes?"
                    description="If you recently submitted an update, you will see changes to your property profile once your update is verified. Please check for an email from SageSure covering any next steps or call your insurance representative if you have any questions."
                  />
                )}
              </div>
            )}
          </section>

          <section className={styles.propertySection}>
            <Heading size="sm">
              <div className={styles.propertySectionIconDS}>
                <Icon name="Quote" size="lg" />
              </div>
              Security
            </Heading>

            <Body className={styles.sectionDescription}>
              Alarm systems can protect your home and property, and may even
              lower your insurance premium.
            </Body>

            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{burglarAlarm?.title}:</DataTerm>
              <DataDefinition>{burglarAlarm?.value}</DataDefinition>
            </DataRow>

            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{fireAlarm?.title}:</DataTerm>
              <DataDefinition>{fireAlarm?.value}</DataDefinition>
            </DataRow>

            {propertyProfileSecurityDetails && (
              <Button
                appearance="primary"
                size="default"
                className={styles.updateButton}
                onClick={() => setUpdateForm("security")}
                data-testid="update-security-btn"
              >
                Update
              </Button>
            )}
          </section>

          <section className={styles.propertySection}>
            <Heading size="sm">
              <div className={styles.propertySectionIconDS}>
                <Icon name="HouseShield" size="lg" />
              </div>
              Roof
            </Heading>

            <Body className={styles.sectionDescription}>
              Keeping your roof in good repair keeps your home safe and secure.
              Replacing your roof can lower your insurance premium.
            </Body>

            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{constructionYearRoof?.title}:</DataTerm>
              <DataDefinition>{constructionYearRoof?.value}</DataDefinition>
            </DataRow>

            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{roofCoveringType?.title}:</DataTerm>
              <DataDefinition>
                {PROPERTY_ROOF_UPDATE_TYPE[roofCoveringType?.value]?.label ??
                  "None"}
              </DataDefinition>
            </DataRow>

            {propertyProfileDwelling && (
              <Button
                appearance="primary"
                size="default"
                className={styles.updateButton}
                onClick={() => navigate("/my/property/roof")}
                data-testid="update-dwelling-btn"
              >
                Update
              </Button>
            )}
          </section>

          <section className={styles.propertySection}>
            <Heading size="sm">
              <div className={styles.propertySectionIconDS}>
                <Icon name="Pool" size="lg" />
              </div>
              Swimming pool
            </Heading>

            <Body className={styles.sectionDescription}>
              Accurate and up-to-date property information helps us ensure you
              have the best coverage.
            </Body>

            {propertyPagePoolValues ? (
              <PoolData poolData={poolData} />
            ) : (
              <>
                <DataRow flex="row" className={styles.dataTerm}>
                  <DataTerm>Type:</DataTerm>
                  <DataDefinition>{poolType}</DataDefinition>
                </DataRow>

                {poolType !== "None" && (
                  <DataRow flex="row" className={styles.dataTerm}>
                    <DataTerm>Pool fence:</DataTerm>
                    <DataDefinition>
                      {poolFence ? "Yes" : "None"}
                    </DataDefinition>
                  </DataRow>
                )}
              </>
            )}

            {propertyProfilePool && (
              <Button
                appearance="primary"
                size="default"
                className={styles.updateButton}
                data-testid="update-pool-btn"
                onClick={() => setUpdateForm("pool")}
              >
                Update
              </Button>
            )}
          </section>

          <section className={styles.propertySection}>
            <Heading size="sm">
              <div className={styles.propertySectionIconDS}>
                <Icon name="Trampoline" size="lg" />
              </div>
              Trampoline
            </Heading>

            <Body className={styles.sectionDescription}>
              Updating details about your property helps ensure your coverage
              meets your needs.
            </Body>

            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{trampoline?.title}:</DataTerm>
              <DataDefinition>{trampoline?.value}</DataDefinition>
            </DataRow>

            {propertyProfileTrampoline && (
              <Button
                appearance="primary"
                size="default"
                className={styles.updateButton}
                onClick={() => setUpdateForm("trampoline")}
                data-testid="update-trampoline-btn"
              >
                Update
              </Button>
            )}
          </section>
        </Card>
      )}
    </div>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={sidebar}> {mainContent}</MainLayout>
      <CustomerCareContactModal
        description="We can assist with any questions about billing or your SageSure account."
        handleClose={() => setShowContactModal(false)}
        show={showContactModal}
      />
    </BaseLayout>
  );
};

export default PropertyProfile;
