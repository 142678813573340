import { Dispatch, SetStateAction, createContext, useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import { PageFooter } from "components/shared/page-footer";
import { UnauthHeader } from "components/shared/unauth-header";

import styles from "./shingle-repair-pilot.module.scss";

export type ShingleContextState = {
  policyId: string;
  firstName: string;
  lastName: string;
  propertyAddress: {
    street1?: string | null;
    street2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  email: string;
  phoneNumber: string;
  phoneNumberFormatted: string;
  insightPolicyId: string | null;
} | null;

export const ShingleContext = createContext<{
  state: ShingleContextState;
  setState: Dispatch<SetStateAction<ShingleContextState>>;
}>({ state: null, setState: () => null });

const ShingleRepairPilot = () => {
  const [state, setState] = useState<ShingleContextState>(null);

  return (
    <ShingleContext.Provider value={{ state, setState }}>
      <UnauthHeader />
      <Container className={styles.content}>
        <Outlet />
      </Container>
      <PageFooter />
    </ShingleContext.Provider>
  );
};

export default ShingleRepairPilot;
