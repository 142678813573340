import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { Button } from "@icg360/design-system";
import { PaymentController } from "@icg360/payment-toolkit-v2";

import { payControl } from "utils";
import { type PaymentConfig } from "utils";

type PaymentButtonProps = PropsWithChildren<{
  payment: PaymentConfig;
  onPaymentError?: (error: Error) => void;
  onPaymentCanceled?: () => void;
  onPaymentComplete?: (response) => void;
  onPaymentStart?: () => void;
  onScheduledPaymentCreated?: (response) => void;
}>;

export const PaymentButton = ({
  payment,
  onPaymentStart,
  onPaymentCanceled,
  onPaymentComplete,
  onPaymentError,
  onScheduledPaymentCreated,
  children,
}: PaymentButtonProps) => {
  const [oneIncPortalReady, setIsOneIncPortalReady] = useState(
    payControl?.isReady() ?? false
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onPaymentReady = () => {
      setIsOneIncPortalReady(true);
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_READY,
      onPaymentReady
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_READY,
        onPaymentReady
      );
  }, []);

  useEffect(() => {
    const eventHandler = () => {
      setLoading(false);
      onPaymentStart?.();
    };
    payControl?.addEventListener(PaymentController.PAYMENT_START, eventHandler);
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_START,
        eventHandler
      );
  }, [onPaymentStart]);

  useEffect(() => {
    const eventHandler = () => {
      setLoading(false);
      onPaymentCanceled?.();
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_CANCEL,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_CANCEL,
        eventHandler
      );
  }, [onPaymentCanceled]);

  useEffect(() => {
    const eventHandler = (error) => {
      setLoading(false);
      onPaymentError?.(error);
    };
    payControl?.addEventListener(PaymentController.PAYMENT_ERROR, eventHandler);
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_ERROR,
        eventHandler
      );
  }, [onPaymentError]);

  useEffect(() => {
    const eventHandler = (response) => {
      onPaymentComplete?.(response);
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_COMPLETE,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_COMPLETE,
        eventHandler
      );
  }, [onPaymentComplete]);

  useEffect(() => {
    const eventHandler = (response) => {
      onScheduledPaymentCreated?.(response);
    };
    payControl?.addEventListener(
      PaymentController.SCHEDULED_PAYMENT_CREATED,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.SCHEDULED_PAYMENT_CREATED,
        eventHandler
      );
  }, [onScheduledPaymentCreated]);

  const onClick = useCallback(() => {
    setLoading(true);
    payControl?.makePayment(payment);
  }, [payment]);

  return (
    <Button disabled={!oneIncPortalReady} loading={loading} onClick={onClick}>
      {children}
    </Button>
  );
};
