import { Container } from "react-bootstrap";
import { useRouteError } from "react-router-dom";

import { Alert, Button, Heading, Span, SpotIcon } from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import LiveChatButton from "components/live-chat-button";
import { GENERAL_SUPPORT } from "consts";
import { useIsLoggedInQuery } from "gql/__generated__/hooks";
import { useLogout } from "hooks/use-logout-user";
import { isBusinessHours } from "utils";

import styles from "./full-page-error.module.scss";

const FullPageError = () => {
  const { logout } = useLogout();
  const error = useRouteError();
  console.error("Route Error:", error);
  const { data: isLoggedInResponse } = useIsLoggedInQuery();
  const loggedIn = isLoggedInResponse?.isLoggedIn?.loggedIn;

  return (
    <Container className={styles.container}>
      <div className={styles.centeredContent}>
        <div className={styles.mainIcon}>
          <SpotIcon name="RainCloudError" />
        </div>
        <Heading size="lg" className={styles.mainHeading}>
          We apologize. Something went wrong on our end.
        </Heading>
        <Heading size="sm">You could try one of these options:</Heading>
        <ul className={styles.bulletedList}>
          <li>{`Wait a few minutes and try again`}</li>
          <li>{`Log in using "Incognito" or "Private" browser mode`}</li>
          <li>{`Clear the browser's cookies and cache`}</li>
        </ul>
        {loggedIn ? (
          <Button
            appearance="secondary"
            size="sm"
            className={styles.logoutButton}
            onClick={logout}
          >
            Log out
          </Button>
        ) : null}
        <Heading size="sm">
          If none of the above works, we are here to help take a look at your
          account:
        </Heading>
        <LiveChatButton location="FullPageError" />

        <div className={styles.contactLinks}>
          <PhoneIconLink phone={GENERAL_SUPPORT} />
          <EmailIconLink email="customercare@sagesure.com" />
        </div>
        {!isBusinessHours() && (
          <Alert
            className={styles.businessHoursAlert}
            title=""
            description={
              <>
                Our business hours are{" "}
                <Span bold>
                  Monday-Friday 8am - 9pm ET, Saturday 10am - 3pm ET
                </Span>
                . If you leave a message outside our business hours, we will get
                back to you later.
              </>
            }
          />
        )}
      </div>
    </Container>
  );
};

export default FullPageError;
