import { Heading } from "@icg360/design-system";

import { SectionCard } from "components/common/card";
import { useEnvelopeToSign } from "hooks/use-envelope-to-sign";
import { DocumentSignRow } from "pages/documents/documents/self-service-center/document-sign-row";

import * as styles from "./self-service-center.module.scss";

const SelfServiceCenter = () => {
  const { envelopeToSign } = useEnvelopeToSign();

  return envelopeToSign ? (
    <SectionCard title={<Heading size="md">Self service center</Heading>}>
      <div className={styles.content}>
        <DocumentSignRow
          documentType={envelopeToSign.documentType}
          documentStatus={envelopeToSign.documentStatus}
          envelopeId={envelopeToSign.id}
        />
      </div>
    </SectionCard>
  ) : null;
};

export { SelfServiceCenter };
