import { Form, useFormikContext } from "formik";
import { Col } from "react-bootstrap";

import { Alert, Button, Card, Heading } from "@icg360/design-system";

import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { PoolFormStep } from "consts";
import { en as locale } from "locale";
import {
  UpdatePoolValueKeys,
  UpdatePoolValues,
} from "pages/property-profile/update-pool/update-pool-form.const";

import styles from "./update-pool.module.scss";

// These columns appear to be rendering as one column...
const column1: UpdatePoolValueKeys[] = [
  "poolType",
  "poolFence",
  "poolFenceMinimumHeight",
  "poolFenceLockingGate",
  "aboveGroundPoolMinimumHeight",
  "divingBoard",
];
const column2: UpdatePoolValueKeys[] = [
  "poolSlide",
  "poolFilled",
  "poolCovered",
  "poolImmovableLadder",
  "poolAttachedToDecking",
  "poolDeckGateLocks",
];
const dependentOnNo: Partial<Record<UpdatePoolValueKeys, UpdatePoolValueKeys>> =
  {
    poolFenceLockingGate: "poolFence",
    poolFenceMinimumHeight: "poolFence",
    poolDeckGateLocks: "poolAttachedToDecking",
  };
const dependentOnYes = {
  poolCovered: "poolFilled",
};

const valueLabel = (val: string | boolean) => {
  if (typeof val === "boolean") {
    return val ? "Yes" : "No";
  }

  switch (val) {
    case "NONE":
      return "None";
    case "INGROUNDPOOL":
      return "In-ground";
    case "ABOVEGROUNDPOOL":
      return "Above-ground";
  }

  // Shouldn't get here
  return val;
};

const UpdatePoolReview = ({ setReview, setShownCard, error }) => {
  const { isSubmitting, values } = useFormikContext<UpdatePoolValues>();
  const { poolAttachedToDecking, poolType } = values;
  let stepBack: PoolFormStep = poolAttachedToDecking
    ? "poolDeckGateLocks"
    : "poolAttachedToDecking";
  if (poolType === "NONE") {
    stepBack = "poolType";
  }

  return (
    <Card className={styles.reviewCard}>
      <Form>
        <Stack gap="md" className={styles.reviewStack}>
          <Heading size="lg">{locale.propertyProfile.pleaseReview}</Heading>
          {error && <Alert appearance="danger" title="" description={error} />}
          <Heading size="sm">{locale.propertyPage.pool}</Heading>
          <div>{locale.updatePool.poolReview}</div>
          <div className={styles.reviewValues}>
            {[column1, column2].map((column, id) => {
              return (
                <Col
                  md={6}
                  xs={12}
                  key={`column${id}`}
                  className={styles.reviewColumn}
                >
                  {column.map((key) => {
                    const val = values[key];
                    const hide =
                      (!!dependentOnNo[key] &&
                        !values[dependentOnNo[key] ?? ""]) ||
                      (!!dependentOnYes[key] && values[dependentOnYes[key]]) ||
                      val === null ||
                      val === undefined;

                    if (!hide) {
                      return (
                        <InlineDataRow className={styles.reviewItem} key={key}>
                          <DataTerm>
                            {locale.updatePool.reviewTitles[key]}:
                          </DataTerm>
                          <DataDefinition>{valueLabel(val)}</DataDefinition>
                        </InlineDataRow>
                      );
                    }
                  })}
                </Col>
              );
            })}
          </div>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onClick={() => {
                setReview(false);
                setShownCard(stepBack);
              }}
            >
              {locale.propertyProfile.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="pool-submit-btn"
            >
              {isSubmitting
                ? locale.propertyProfile.submitting
                : locale.propertyProfile.submitUpdates}
            </Button>
          </div>
        </Stack>
      </Form>
    </Card>
  );
};

export default UpdatePoolReview;
