import { PropsWithChildren, ReactNode } from "react";

import { Heading, Icon, Span } from "@icg360/design-system";

import { RegularContentLoader } from "components/loader";
import { type Address, formatAddress } from "utils";

import styles from "./settings-policy.module.scss";

export type SettingsPolicyDetailItem = {
  label: string;
  value: ReactNode;
};

type SettingsPolicyProps = PropsWithChildren<{
  address?: Address | null;
  isCommercial: boolean;
  className?: string;
  detailItems?: SettingsPolicyDetailItem[];
  loading?: boolean;
}>;

const SettingsPolicy = ({
  address,
  isCommercial,
  detailItems = [],
  children,
  loading = false,
}: SettingsPolicyProps) => {
  if (loading) {
    return <RegularContentLoader />;
  }

  return (
    <div className={styles.policyItemHeader}>
      <div className={styles.policyIcon}>
        <Icon name={isCommercial ? "Store" : "House"} size="lg" />
      </div>
      <div className={styles.content}>
        <Heading size="sm">{formatAddress(address, true)}</Heading>
        {detailItems.map(({ label, value }) => {
          return (
            <div key={label} className={styles.detailItem}>
              <Span bold>{label}</Span>
              <Span className={styles.valueDS}>{value}</Span>
            </div>
          );
        })}
        {children}
      </div>
    </div>
  );
};

export default SettingsPolicy;
