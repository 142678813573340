import { Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";

import { Heading, SpotIcon } from "@icg360/design-system";
import {
  PAYMENT_COMPLETE,
  PAYMENT_ERROR,
  PAYMENT_START,
} from "@icg360/payment-toolkit-v2";

import UnauthLayout from "components/shared/unauth-layout/index";
import { policyZipCodeSchema } from "consts";
import { en as locale } from "locale";
import {
  acceptPaymentOnCancelledPolicy,
  logError,
  payControl,
  scrollToTop,
  trackEvent,
} from "utils";

import AltPaymentBanner from "./alt-payment-banner";
import { BillPayForm } from "./bill-pay-form";
import { usePaymentInfo } from "./hooks";

export const BillPay = () => {
  const { verify } = usePaymentInfo();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleSubmit = async (
    { policyNumber, zipCode },
    { setSubmitting, setStatus }
  ) => {
    trackEvent("Start Making Express Payment");
    setStatus(null);

    const { data, error } = await verify(policyNumber, zipCode);
    const handleError = (error: string) => {
      trackEvent("Error Displayed (Payment)", { error });
      setStatus({ type: "error", message: error });
      setSubmitting(false);
    };

    if (error) {
      return handleError(error);
    }

    const {
      policyId,
      quoteId,
      insightPolicyId,
      email,
      phone,
      accountBalance,
      minAmountDue,
      policyState,
      totalPremium,
      policyStateAttributes,
    } = data;

    let reasonCode, effectiveDate;
    if (policyStateAttributes) {
      ({ reasonCode, effectiveDate } = policyStateAttributes);
    }
    const cancelledButEligibleForRenewal = acceptPaymentOnCancelledPolicy(
      policyState,
      reasonCode && reasonCode.value,
      effectiveDate,
      minAmountDue
    );

    if (!cancelledButEligibleForRenewal) {
      if (policyState !== "ACTIVEPOLICY") {
        setSubmitting(false);
        trackEvent("Error Displayed (Payment)", {
          error: locale.paymentPage.errorPaymentNotActive,
        });
        return handleError(locale.paymentPage.errorPaymentNotActive);
      } else if (accountBalance <= 0) {
        setSubmitting(false);
        trackEvent("Error Displayed (Payment)", {
          error: locale.paymentPage.errorPaymentNoBalance,
        });
        return handleError(locale.paymentPage.errorPaymentNoBalance);
      }
    }

    payControl.addEventListener(PAYMENT_COMPLETE, (response) => {
      setSubmitting(false);
      setStatus({
        type: "success",
        message: locale.paymentPage.successPayment,
      });
      (document.getElementById("bill-payment") as HTMLFormElement)?.reset();
      trackEvent("Submit Express Payment", response);
    });

    payControl.addEventListener(PAYMENT_START, () => {
      setSubmitting(false);
    });

    const finalPayDate = moment().utc().add(30, "days").format("YYYY-MM-DD");

    const minAmountDueScheduled = minAmountDue < 1 ? 1 : minAmountDue;

    payControl.addEventListener(PAYMENT_ERROR, (error) => {
      setStatus({
        type: "error",
        message:
          "There was an error while submitting. Please refresh your browser and try again.",
      });
      setSubmitting(false);
      const errorMessage = error?.message ?? "Unknown Error";
      logError(`Payment Error (unauthenticated) ${errorMessage}`);
      trackEvent("Payment Error (unauthenticated)");
    });

    payControl.makePayment({
      minAmountDue: Number(minAmountDueScheduled),
      accountBalance: Number(
        cancelledButEligibleForRenewal ? totalPremium : accountBalance
      ),
      insightPolicyId,
      paymentApplicationClient: "2000",
      policyId,
      email,
      phone,
      quoteId,
      isScheduledPayEnabled: true,
      finalPayDate,
    });
  };

  return (
    <>
      <UnauthLayout title="Pay my bill | SageSure">
        <SpotIcon name="Bill" appearance="bold" />
        <Heading size="lg">{locale.paymentPage.payment}</Heading>
        <div>{locale.paymentPage.paymentDescription}</div>
        <Formik
          initialValues={{
            policyNumber: "",
            zipCode: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={policyZipCodeSchema}
        >
          <BillPayForm />
        </Formik>
      </UnauthLayout>
      <AltPaymentBanner />
    </>
  );
};
