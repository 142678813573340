import cx from "classnames";
import { Field, Form, useFormikContext } from "formik";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Button, Link as DSLink, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { Stack } from "components/common/stack";
import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { en as locale } from "locale";
import { setMixpanelSuperProperties, trackEvent } from "utils";

import styles from "./ceremony-link-form.module.scss";

type CeremonyLinkFormSchema = {
  selectedEmail: string;
};

type CeremonyLinkFormProps = {
  emailList: string[];
  policyNumber: string;
};

const CeremonyLinkForm = ({
  emailList,
  policyNumber,
}: CeremonyLinkFormProps) => {
  setMixpanelSuperProperties({
    policyNumber,
    listOfEmails: emailList,
  });

  const navigate = useNavigate();
  const { values, isValid } = useFormikContext<CeremonyLinkFormSchema>();

  useEffect(() => {
    trackEvent("GEICO: Link Account Page Loaded");
  }, []);

  const [firstEmail] = emailList;

  return (
    <Form className={cx("form-inline", styles.formWrapper)}>
      <Heading size="md" className={styles.formTitle}>
        {locale.ceremony.link.title(emailList.length > 1)}
      </Heading>
      <Body className={styles.description}>
        {locale.ceremony.link.description(emailList.length > 1, policyNumber)}
      </Body>
      {emailList.length > 1 ? (
        <Row className={styles.radioWrapper}>
          <Col md={12} className={styles.radioGroup}>
            <Field
              id="selectedEmail"
              component={RadioButtonGroup}
              fieldChildren={() =>
                emailList.map((email) => (
                  <Field
                    key={email}
                    name="selectedEmail"
                    id={email}
                    label={email}
                    component={RadioButtonField}
                    checked={values.selectedEmail === email}
                    className={cx(
                      values.selectedEmail === email ? "checked" : "",
                      styles.radioOption
                    )}
                  />
                ))
              }
            />
          </Col>
        </Row>
      ) : (
        <p className={styles.singleEmail}>{firstEmail}</p>
      )}

      <Body className={styles.promptText}>
        {locale.ceremony.link.prompt(emailList.length > 1)}
      </Body>
      <Stack gap="sm">
        <Button
          type="submit"
          disabled={!isValid}
          data-testid="ceremony-submit-btn"
          fluid
        >
          {locale.ceremony.link.linkButton}
        </Button>
        <DSLink
          onClick={() => {
            if (emailList.length < 2) {
              trackEvent("GEICO: Create Account Button Clicked");
            }
            navigate("/ceremony/register");
          }}
        >
          {locale.ceremony.link.createAccount}
        </DSLink>
      </Stack>
    </Form>
  );
};

export default CeremonyLinkForm;
