import cx from "classnames";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Heading, Icon } from "@icg360/design-system";

import { Body } from "components/common/body";
import { coveragePremiums, coverages, extendedCoveragesCopy } from "consts";
import { trackEvent, useFlags } from "utils";

import { CoverageContext } from "../hooks";
import styles from "./coverage-card.module.scss";

const coverageIcons = {
  [coverages.IDENTITY]: "Theft",
  [coverages.MECHANICAL]: "Tools",
  [coverages.SERVICE]: "ServiceRequest",
};

const CoverageCard = ({ coverage, listOfCoverages }) => {
  const { updateCoverageRoute } = useFlags();
  const navigate = useNavigate();
  const { setCoveragePage } = useContext(CoverageContext);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const setCoverage = () => {
    trackEvent("Coverage Button Clicked", {
      coverageName: coverage.name,
      coveragePrice: coveragePremiums[coverage.name],
      listOfCoverages: listOfCoverages.map((c) => c.name),
    });

    if (updateCoverageRoute) {
      navigate("/my/update-coverage");
    } else {
      setCoveragePage({
        policyId: "",
        type: "open",
        y: window.pageYOffset,
      });
    }
  };

  return (
    <>
      <Card border>
        <div className={styles.coverageCard}>
          <div className={styles.coverageIcon}>
            <Icon name={coverageIcons[coverage.name]} size="xl" />
          </div>

          <div className={styles.coverageMeta}>
            <Heading size="sm" className={styles.coverageName}>
              {extendedCoveragesCopy[coverage.name].short}
            </Heading>

            <Heading size="xs" className={styles.coverageYearlyPrice}>
              ${coveragePremiums[coverage.name]} / YEAR
            </Heading>

            <Body className={styles.description}>
              {extendedCoveragesCopy[coverage.name].description.short}
            </Body>
          </div>

          <Button
            appearance="primary"
            size="default"
            id={`purchase_${coverage.name}`}
            className={cx(styles.purchaseButton, styles.hiddenMobile)}
            onClick={setCoverage}
            data-testid="extended-coverage-check-price-btn"
          >
            Check price
          </Button>
        </div>

        <div className={cx(styles.coverageCard, styles.coverageCardSecondary)}>
          <div className={styles.hiddenLeft} />

          <div className={styles.coverageMeta}>
            {showFullDescription && (
              <Body
                className={cx(styles.description, styles.expandedDescription)}
              >
                {extendedCoveragesCopy[coverage.name].description.expanded(() =>
                  trackEvent(
                    `Coverage Fact Sheet PDF downloaded: ${coverage.name}`
                  )
                )}
              </Body>
            )}

            <Button
              appearance="tertiary"
              size="sm"
              onClick={() => setShowFullDescription((s) => !s)}
              data-testid="see-more-btn"
            >
              {showFullDescription ? "See less..." : "See more..."}
            </Button>
          </div>

          <div className={styles.hiddenRight}>
            <Button
              appearance="primary"
              id={`purchase_${coverage.name}`}
              className={cx(styles.purchaseButton, styles.hiddenDesktop)}
              onClick={setCoverage}
              data-testid="extended-coverage-check-price-mobile-btn"
            >
              Check price
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

CoverageCard.propTypes = {
  coverage: PropTypes.shape({
    name: PropTypes.string,
    protectionValue: PropTypes.number,
  }),
  selected: PropTypes.bool,
  handleCoverageClicked: PropTypes.func,
  className: PropTypes.string,
  insuranceRep: PropTypes.shape({
    agentEmail: PropTypes.string,
    agentPhone: PropTypes.string,
  }),
  handleSendMessageModalOpen: PropTypes.func,
  handlePurchaseModalOpen: PropTypes.func,
};

export default CoverageCard;
